import React, { Component } from "react";
import Heading from "../../common/heading/heading";

class terrazaCardGroup extends Component {
  render() {
    return (
      <div className="ourservices" id="#reasons">
        <div className="container">
          <Heading>TOP 6 REASON TO MAKE VERZURE YOUR DREAM ABODE!
</Heading>
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                     <img src= '/assets/imagewebp/project/ch.png' className="eq"></img>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                    Techies' Paradise
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                    7 Minutes away from Manyata Tech Park, your commute just got a whole lot shorter!

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2 ">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                    <img src= '/assets/imagewebp/project/hm.png' className="eq"></img>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                    Thoughtfully Designed Spaces
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                    Thoughtfully designed living spaces offering comfort and room for personalized decor and lifestyle.

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                    <img src= '/assets/imagewebp/project/dd.png' className="eq"></img>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                    Double Delight
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                    Two Balconies per Home, offering the perfect spot for your morning tea and evening stargazing.

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                    <img src= '/assets/imagewebp/project/11.png' className="eq"></img>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                    Abundant Light & Ventilation
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                    Experience homes radiating with natural light and fresh air, enhancing comfort and well-being.

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                    <img src= '/assets/imagewebp/project/ne.png' className="eq"></img>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                    Nature's Embrace
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                    Gold Standard Green Living, where sustainability meets luxurious living in perfect harmony.

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                    <img src= '/assets/imagewebp/project/111.png' className="eq"></img>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                    Life, Upgraded
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                    Modern Lifestyle Amenities, transforming your everyday into an extraordinary experience at every turn.

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-4 col-md-6 d-flex" />

            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa fa-cog" aria-hidden="true"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Zero Risk Investment
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Ready to Move Homes with OC
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex" />
          </div> */}
        </div>
      </div>
    );
  }
}

export default terrazaCardGroup;
