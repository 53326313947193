import React, { Component } from "react";
import Heading from "../../common/heading/heading";

class terrazaCardGroup extends Component {
  render() {
    return (
      <div className="ourservices" id="#reasons">
        <div className="container">
          <Heading>Top 7 Reasons to Make Terraza Your Home</Heading>
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa fa-lock" aria-hidden="true"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Spacious Private Terraces
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      make your own private terrace garden or enjoy a pleasant
                      sundowner with beautiful city views!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2 ">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa fa-home" aria-hidden="true"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Spacious Homes
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Now, you will never complain of less space in your home!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa fa-user-circle" aria-hidden="true"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      The Lifestyle you Deserve
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Multitude of modern amenities and a lavish clubhouse for
                      you to lead a lavish lifestyle
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa fa-map-marker" aria-hidden="true"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Walk to Work
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Terraza is located just 200 meters from Manyata Tech Park
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa fa-usd" aria-hidden="true"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Huge Capital Appreciation
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      The proximity to Manyata Tech Park & value for money
                      pricing will give you high returns
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa fa-bookmark" aria-hidden="true"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Every Home is a Corner Home
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Yes, that’s one of the most unique features of Terraza’s
                      design!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex" />

            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa fa-cog" aria-hidden="true"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Zero Risk Investment
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Ready to Move Homes with OC
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex" />
          </div>
        </div>
      </div>
    );
  }
}

export default terrazaCardGroup;
