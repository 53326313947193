import React, { Component } from "react";
import Heading from "../../common/heading/heading";

class terrazaCardGroup extends Component {
  render() {
    return (
      <div className="ourservices" id="#reasons">
        <div className="container">
          <Heading>TOP 6 REASON TO CHOOSE PREMIA</Heading>
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                    <img src= '/assets/imagewebp/project/p1.png' className="eq"></img>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                    Sky High Comfort
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                    Enjoy extra comfort with large balconies and decks in Premia, making your living space feel like a cozy retreat in the sky.

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2 ">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                    <img src= '/assets/imagewebp/project/p2.png' className="eq"></img>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                    Close-Knit Convenience
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                    It Takes 20 Minutes To Reach Bangalore International Airport Via NH-7 Or Airport Road, While Tech Park Road To Manyata Takes 2-3 Minutes.

                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                    <img src= '/assets/imagewebp/project/p3.png' className="eq"></img>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      
Architectural Marvel
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                    Premia showcases a perfect blend of precise architectural design and superb craftsmanship, creating a timeless residential masterpiece.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                    <img src= '/assets/imagewebp/project/p4.png' className="eq"></img>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                    Distinctive Crown Living
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                    Indulge in luxury at Premia's rooftop infinity pool, offering a daily enchanting view of the city's skyline for a truly exquisite living experience.


                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                    <img src= '/assets/imagewebp/project/p5.png' className="eq"></img>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                    Premium Brand Alliances
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                    Explore top-notch brands like Jaquar, Anchor Roma, Artize, and more, adding premium materials and craftsmanship to adorn your home with sophistication.


                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                    <img src= '/assets/imagewebp/project/p6.png' className="eq"></img>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                    Luxurious Oasis Living
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                    Pamper yourself with 30+ luxurious indoor &outdoor amenities for relaxation, surrounded by diverse landscaping that brings nature's beauty right to your doorstep.

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-4 col-md-6 d-flex" />

            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i class="fa fa-cog" aria-hidden="true"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Zero Risk Investment
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      Ready to Move Homes with OC
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex" />
          </div> */}
        </div>
      </div>
    );
  }
}

export default terrazaCardGroup;
